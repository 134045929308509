import axios from 'axios'
import Swal from 'sweetalert2'
// import _ from 'lodash';
export default class PlannerCodeClass{
    constructor(){
        this.planners=[]
    }   
    getplannersCode(aws,obj,apiKey){
        return new Promise(resolve=>{
            axios.post(`${aws}getPlannersCode`,obj,apiKey).then((r) => {
                if(r.data.length > 0){
                    this.planners = r.data.filter(r=>{
                        r.EmployeesData=`${r.EmployeeCode +' - '+r.EmployeeName}`
                        return r;
                    })
                    this.planners.sort((a,b)=>{
                        return a.EmployeeCode - b.EmployeeCode
                    })
                
                    resolve({data:this.planners,loading:false})  
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "No data found!",
                        text: `The user are not registered in any Sales Office.`,
                    });
                    resolve({data:this.planners,loading:false})  
                }
                  
            }).catch(err=>{
                alert(err.message)
                resolve({data:this.planners,loading:false})  
            })
        })
    }
}